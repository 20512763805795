.ContentSectionImageFull {
  clear: both;
  margin: 1.5em auto;
  max-width: 730px;
  padding: 0 15px;
}

.ContentSectionImageFull-figure {
  margin: 0 auto;
}

.ContentSectionImageFull-caption {
  font-size: 11px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.ContentSectionImageFull-byline {
  color: #999;
  white-space: nowrap;
}
