.VideoSmallTeaser {
  display: block;

  &:hover, &:active {
    background-color: #D5DDE3;
  }
  &:focus {
    outline: 1px solid;
  }
}

.VideoSmallTeaser--hasImage {
  min-height: 65px;
}

.VideoSmallTeaser-image {
  display: inline-block;
  overflow: hidden;
  margin-right: 10px;
  width: 100px;
  height: 100%;
  border-radius: 3px;
  vertical-align: middle;
}

.VideoSmallTeaser-content {
  display: inline-block;
  vertical-align: middle;
  line-height: 20px;
}

.VideoSmallTeaser-content--hasImage {
  width: calc(100% - 110px);
}

.VideoSmallTeaser-title {
  color: color-get(black);
}

.VideoSmallTeaser-date {
  display: block;
  margin-top: 4px;
  color: #999;
  font-size: 12px;
}
.VideoSmallTeaser-imgCenterer{
  position: relative;
  display: flex;
  padding-top: 65.25%;
  width: 100%;
  height: 0;
  align-items: center;
}

.VideoSmallTeaser-img{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.VideoSmallTeaser-duration{
  position: absolute;
  left: 5%;
  bottom: 10%;
  background: #000 none repeat scroll 0% 0%;
  color: #FFF;
  font-size: 13px;
  border-radius: 3px;
  vertical-align: middle;
  padding: 0px 4px 2px;
}

.VideoSmallTeaser-durationText {
  vertical-align: middle;
  margin-left: 2px;
}