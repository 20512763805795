.Theme {
  text-align: center;
  margin-bottom: 20px;
  padding: 0 10px 0;
}

.Theme-heading {
  margin: 0 0 10px;
}

.Theme-description {
  line-height: 1.4em;
  max-width: 540px;
  margin: 0px auto 5px;
}

.Theme-info {
  color: #999;
}