.ReviewFull {
  padding-bottom: 2%;
}

.ReviewFull-filed {
  margin: 15px auto;
  max-width: 730px;
  padding-left: 15px;
  padding-right: 15px;
}

.ReviewFull-subheading {
  margin-top: -20px;
  color: color-get('lightGrey');
  font-weight: 100;
}

.ReviewFull-body{
  margin-top: 30px;
}

.ReviewFull-reviewBox{
  float: right;
  margin: 8px 15px 20px;
  padding-bottom: 3px;
  vertical-align: top;

  @include breakpoint(480px){
    margin-right: 6%;
    margin-left: 6%;
    width: 37%;
  }
}
.ReviewFull-reviewBox-inner {
  border-top: 3px solid color-get(orange);
  border-bottom: 1px solid #EEE;
}

.ReviewFull-reviewBox-heading{
  margin-top:9px;
  margin-bottom: 9px;
}

.ReviewFull-reviewBox-grade{
  margin-bottom: 15px;
  color: color-get(orange);
  font-size: 30px;
}

.ReviewFull-reviewBox-goodBadHeading{
  margin-top: 0px;
  margin-bottom: 0px;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 0.3px;
}

.ReviewFull-reviewBox-goodBadText{
  margin-bottom: 15px;
  margin-top: 0px;
  color: #999;
  font-size: 14px;
}

.ReviewFull-reviewBox-price {
  font-size: 14px;
  border-top: 1px solid #eee;
  color: #999;
  margin: 0;
  padding-top: 3px;
}

.ReviewFull-reviewBox-link {
  display: inline-block;
  font-size: 14px;
  margin-top: 0.5em;
}
