.Promo {
  color: white;
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  overflow: hidden;

  &:hover {
    color: white;
  }

  &:before {
    z-index: 1;
    position: absolute;
    content: " ";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 37%,rgba(0,0,0,.9) 100%);
  }
}

.Promo-image {
  max-width: none;
  position: absolute;
  width: 100%;
  z-index: 0;
}

.Promo-info {
  position: absolute;
  bottom: 0;
  text-shadow: 0 1px 0 rgba(black, 0.5);
  padding: 0 15px 0 15px;
  z-index: 1;
}

.Promo-heading {
  margin: 0 0 15px;
  line-height: 1;

  .Promo:hover & {
    text-decoration: underline;
  }
}

.Promo-heading--main {
  @include breakpoint(900px) {
    margin: 0;
    font-size: 48px;
  }
}

.Promo-lead {
  line-height: 1.4;
  max-width: 620px;
  margin: 10px 0 20px;

  @include breakpoint(max-width 899px) {
    display: none;
  }
}