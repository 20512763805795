.VideoTeaser {
  display: block;
}

.VideoTeaser-image {
  display: inline-block;
  overflow: hidden;
  height: 100%;
  vertical-align: middle;
  max-width: 220px;
  width: 40%;
}

.VideoTeaser-imgCenterer {
  position: relative;
  display: flex;
  padding-top: 56.25%;
  width: 100%;
  height: 0px;
  align-items: center;
}

.VideoTeaser-img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.VideoTeaser-duration {
  position: absolute;
  left: 5%;
  bottom: 10%;
  background: #000 none repeat scroll 0% 0%;
  color: #FFF;
  font-size: 13px;
  border-radius: 3px;
  vertical-align: middle;
  padding: 0px 4px 2px;
}

.VideoTeaser-durationText {
  vertical-align: middle;
  margin-left: 2px;
}


.VideoTeaser-image {
  display: inline-block;
  max-width: 220px;
  width: 40%;
  vertical-align: middle;
}

.VideoTeaser-content {
  display: inline-block;
  padding-left: 15px;
  width: 60%;
  vertical-align: middle;
}

.VideoTeaser-title {
  margin: 0 0 4px;
  color: color-get(black);

  @include breakpoint(max-width 567px) {
    font-size: 18px;
  }

  .VideoTeaser:hover &{
    color: color-get(orange);
  }

  .VideoTeaser:visited & {
    color: #999;
  }
  .VideoTeaser:visited:hover & {
    color: color-get(orange);
  }
}

.VideoTeaser-lead {
  margin: 0;
  color: rgba(0,0,0,0.7);
  font-size: 14px;
  line-height: 20px;

  @include breakpoint(max-width 640px) {
    display: none;
  }
}

.VideoTeaser-date {
  display: block;
  margin-bottom: 4px;
  color: #999;
  font-size: 12px;
}