.BlogPostFull {
}

.BlogPostFull-figure {
  clear: both;
  margin: 40px auto;
  max-width: 730px;
  padding: 0 15px;
}

.BlogPostFull-image {
  margin: 0 auto;
}