.Icon {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}

.Icon-svg {
  display: inline-block;
  width: inherit;
  height: inherit;
  vertical-align: top;
}

// Icons

.Icon--clock {
  width: 20px;
  height: 19px;
}

.Icon--menuArrowLeft,
.Icon--menuArrowRight {
  width: 8px;
  height: 13px;
}

.Icon--playCircle {
  width: 40px;
  height: 40px;
  fill: white;
}

.Icon--play {
  width:16px;
  height: 16px;
  fill:#fff;
}

.Icon--star {
  height: 19px;
  width: 20px;
  fill: #f1c40f;
  vertical-align: top;
}

.Icon--starHalf {
  height: 19px;
  width: 20px;
  fill: #f1c40f;
  vertical-align: top;
}

.Icon--starEmpty {
  height: 19px;
  width: 20px;
  fill: #f1c40f;
  vertical-align: top;
}

.Icon--barGraph{
  height: 18px;
  width: 18px;
  fill: #E25400;
  margin-top: -7px;
}

.Icon--twitter,
.Icon--facebook {
  width: 30px;
  height: 30px;
}

.Icon--twitter {
  fill: #2DAAE1;
}

.Icon--facebook {
  fill: #3C5B9B;
}
