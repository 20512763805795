.ThemeSmallTeaser {
  min-height: 80px;
  display: block;
  margin: 10px 0;
  font-size: 14px;

  &:hover, &:active {
    background-color: #D5DDE3;
  }
}

.ThemeSmallTeaser-image {
  display: inline-block;
  overflow: hidden;
  margin-right: 10px;
  width: 80px;
  height: 80px;
  border-radius: 3px;
  vertical-align: middle;
}

.ThemeSmallTeaser-content {
  color: #333;
  display: inline-block;
  vertical-align: middle;
}

.ThemeSmallTeaser-content--image {
  max-width: calc(100% - 90px);
}

.ThemeSmallTeaser-title {
}

.ThemeSmallTeaser-info {
  display: block;
  color: #999;
  font-size: 12px;
}