.TopShelf {
  margin: 0 0 15px;
  border: 1px solid #ddd;
}

.TopShelf-cell {
  height: 145px;
  position: relative;

  @include breakpoint(650px) {
    float: left;
    width: 50%;
    height: 170px;

    &:nth-child(3n):before {
      border-right: 1px solid rgba(255, 255, 255, 0.5);
      content: "";
      height: calc(100% - 1px);
      position: absolute;
      top: 1px;
      width: 0;
      z-index: 1;
    }
  }

  @include breakpoint(break-get(twoColumn)) {
    height: 0;
    padding-bottom: 15%;
    width: 320px;
    max-width: 400px;

    &:nth-child(3n):before {
      display: none;
    }

    &:nth-child(2n):after {
      display: none;
    }
  }

  @include breakpoint((floor(300px / (span(4) / 100%)) + 1)) {
    width: calc(#{span(4)} + 20px);
  }

  &:not(:first-child):after {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    content: "";
    height: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.TopShelf-cell--main {
  @include breakpoint(650px) {
    width: 100%;

    &:after {
      border-left: 1px solid rgba(255, 255, 255, 0.5);
      content: "";
      height: 100%;
      position: absolute;
      top: 0;
      width: 0;
      right: 0;
      z-index: 1;
    }
  }

  @include breakpoint(break-get(twoColumn)) {
    height: 0;
    padding-bottom: 30%;
    max-width: 100%;
    width: calc(100% - 320px);
  }

  @include breakpoint((floor(300px / (span(4) / 100%)) + 1)) {
    width: calc(100% - #{span(4)} - 20px);
  }

  @include breakpoint(1200px) {
    width: calc(100% - 400px);
  }

}
