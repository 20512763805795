.TwoColumns {
  margin: 20px 0;
}

.TwoColumns-main {
  background-color: #fff;
  border: 1px solid rgb(221, 221, 221);

  @include breakpoint(break-get(twoColumn)) {
    float: left;
    width: calc(100% - 320px);
  }

  @include breakpoint((floor(300px / (span(4) / 100%)) + 1)) {
    width: calc(100% - #{span(4)} - 20px);
  }

  @include breakpoint(1200px) {
    width: calc(100% - 400px);
  }
}

.TwoColumns-sidebar {
  display: block;
  padding: 0;
  min-width: 300px;
  max-width: 380px;
  min-height: 100vh;
  margin-top: 20px;
  @include breakpoint(break-get(twoColumn)) {
    margin-top: 0;
    width: span(4);
    float: right;
  }
}
