.RecommendedThemes {
  margin: 10px;
}


.RecommendedThemes-theme:not(:first-child) {
  border-top: 1px solid #eee;
  margin-top: 15px;
}

.RecommendedThemes-list {
  margin: 0;
  padding: 0 0 0 20px;
  font-size: 12px;
  line-height: 1.45;
}

.RecommendedThemes-listHeading {
  margin: 10px 0 0;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: bold;
}

.RecommendedThemes-list-item {
  color: #999;
}

.RecommendedThemes-theme-title {
  margin: 10px 0 5px;
  font-size: 18px;
}

.RecommendedThemes-theme-desc > p {
  color: #666;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
}