/* first for IE 4–8 */
@font-face {
  font-family: "metroflexnarrow";
  src: url("../fonts/metroflex_narrow/MeNBd___Web.eot");
}

@font-face {
  font-family: "metroflexnarrow";
  src: url("../fonts/metroflex_narrow/MeNLt___Web.eot");
  font-weight: 100;
}

@font-face {
  font-family: "metroflexnarrow";
  src: url("../fonts/metroflex_narrow/MeNBd___Web.eot");
  font-weight: 700;
}

/* then for WOFF-capable browsers */
@font-face {
  font-family: "metroflexnarrow";
  src: url("../fonts/metroflex_narrow/MeNRg___Web.woff") format("woff");
}

@font-face {
  font-family: "metroflexnarrow";
  src: url("../fonts/metroflex_narrow/MeNLt___Web.woff") format("woff");
  font-weight: 100;
}

@font-face {
  font-family: "metroflexnarrow";
  src: url("../fonts/metroflex_narrow/MeNBd___Web.woff") format("woff");
  font-weight: 700;
}
