.ContentSectionVideoTeaser {
  display: inline-block;
  overflow: hidden;
  height: 100%;
  vertical-align: middle;
  width: 100%;
}

.ContentSectionVideoTeaser-imgCenterer {
  position: relative;
  display: flex;
  padding-top: 56.25%;
  width: 100%;
  height: 0px;
  align-items: center;
}

.ContentSectionVideoTeaser-img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.ContentSectionVideoTeaser-duration {
  position: absolute;
  left: 5%;
  bottom: 10%;
  background: #000 none repeat scroll 0% 0%;
  color: #FFF;
  font-size: 13px;
  border-radius: 3px;
  vertical-align: middle;
  padding: 0px 4px 2px;
}

.ContentSectionVideoTeaser-durationText {
  vertical-align: middle;
  margin-left: 2px;
}