.ThemesCompactList {
  margin: 10px 10px 0;
  padding: 0;
  list-style: none;
}

.ThemesCompactList-item {
  font-size: 12px;
  &:not(:last-child) {
    border-bottom: 1px solid #EEE;
  }
}

.ThemesCompactList-link {
  display: block;
  padding: 3px 0px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}
