$InputForm-errorColor: #8c2e0b;

.InputForm {

  .field-group-fieldset {
    margin: 0 15px;
  }
}

.InputForm-wrapper {
  margin: 15px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 15px;

  > .InputForm-item {
    margin: 20px 0;
  }

  .filter-wrapper {
    display: none;
  }
}

.InputForm-item--option{
  position: relative;
  margin-top: 4px;
  margin-bottom: 4px;
}

.InputForm-item--multipleValueForm {
  margin-top: 0;
  margin-bottom: 10px;
}

.InputForm-multipleValueForm-tableWrapper{
  margin-bottom: 0;
}

.InputForm-actions {
  margin: 15px 15px 0;
  border-bottom: none;

  input + input {
    margin-left: 10px;
  }
}

.InputForm-text {
  border: 2px solid #e5e5e5;
  background-color: #fff;
  height: 40px;
  min-height: 40px;
  padding: 0 11px;
  width: 100%;
  max-width: 450px;
  margin: 0;
  display: block;
}

.InputForm-text--area {
  max-width: 750px;
  min-height: 180px;
  resize: vertical;
}

.InputForm-text--autocomplete{
  background-position: right 11px center !important;
  padding-right: 35px;

  &:disabled{
    background: #FAFAFA;
    cursor: not-allowed;
  }
}

.InputForm-selectWrapper{
  position:relative;
  display: inline-block;
  width: 100%;

  &:after{
    content: '';
    position: absolute;
    display: block;
    width: 16px;
    height: 11px;
    top: 16px;
    right: 14px;
    pointer-events: none;
    z-index: 6;

    .ie8-and-below &,
    .ie9 &{
      background: none;
    }
  }


}

select.InputForm-select,
.select2-container-multi .select2-choices{
  @extend .InputForm-text;
  padding-right: 36px;

  &::-ms-expand {
    display: none;
  }
}

.select2-container-multi.InputForm-select.error{
  background-image: none;
}

.select2-container-multi .select2-choices{
  height: auto;
  padding: 0 35px 8px 0;
  overflow: hidden;

}
.select2-container-multi.error .select2-choices{
  border-color: transparent;
  input{
    color: $InputForm-errorColor;
  }
}

.InputForm-label {
  display: block;
  cursor: pointer;
  margin-bottom: 3px;
  font-weight: normal;
  font-size: 14px;
}

.InputForm-labelWrapper--multipleValueForm{
  text-align: left;
  padding: 0;
}

.InputForm-label--option{
  margin: 0 0 0 21px;
  font-weight: normal;
  font-size: 13px;
}

.InputForm-multipleValueForm-td{
  padding: 0;
}

.InputForm-option {
  position: absolute;
  top: 4px;
}

.InputForm-description {
  color: #999;
  font-size: 12px;
}

.InputForm-item--focalPoint {
  display: none;
}

// CSS hacks for blog posts

.InputForm {
  .tabledrag-toggle-weight-wrapper,
  .file-size,
  .file {
    display: none;
  }

  .tabledrag-handle,
  .image-widget,
  .image-preview,
  .image-widget-data {
    display: inline-block;
    vertical-align: middle;
  }

  .tabledrag-handle {
    background-image: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjMycHgiIHZlcnNpb249IjEuMSIgdmlld0JveD0iMCAwIDEyIDMyIiB3aWR0aD0iMTJweCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMTIgMzIiPiAgPGcgaWQ9IkxheWVyXzEiLz4gIDxnIGlkPSJtb3ZlX3g1Rl92ZXJ0aWNhbCI+ICAgIDxnPiAgICAgIDxwb2x5Z29uIHBvaW50cz0iOCAyMCA4IDI2IDEyIDI2IDYgMzIgMCAyNiA0IDI2IDQgMjAgIiBmaWxsPSIjNEU0RTUwIi8+ICAgICAgPHBvbHlnb24gcG9pbnRzPSI0IDEyIDQgNiAwIDYgNiAwIDEyIDYgOCA2IDggMTIgIiBmaWxsPSIjNEU0RTUwIi8+ICAgIDwvZz4gIDwvZz48L3N2Zz4=");
    cursor: move;
    height: 32px;
    width: 12px;
    margin-right: 10px;
  }

  .image-preview {
    min-width: 100px;
  }

  table {
    width: 100%;

    td {
      padding: 5px 0;
    }

    tr {
      border-bottom: 1px solid #eee;
    }
    .image-widget-data {
      margin: 0 10px;
    }
  }

  thead {
    display: none;
  }

  .sticky-header {
    display: none;
  }
  .image-widget {
    font-size: 14px;

    .form-file {
      max-width: 70%;
    }
  }
  /* Suggestion list */
  #autocomplete {
    border: 1px solid;
    overflow: hidden;
    position: absolute;
    z-index: 100;
  }
  #autocomplete ul {
    list-style: none;
    list-style-image: none;
    margin: 0;
    padding: 0;
  }
  #autocomplete li {
    background: #fff;
    color: #000;
    cursor: default;
    white-space: pre;
    zoom: 1; /* IE7 */
  }
}