.Pane {
  background-color: white;
  border: 1px solid #fff;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);

  & + & {
    margin-top: 20px;
  }
}

.Pane-heading {
  margin: 10px;
  text-transform: uppercase;
  letter-spacing: .3px;
}

.Pane-heading--box {
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}

.Pane-more {
  margin: 0 10px 0;
  display: block;
  border-top: 1px solid #EEE;
  text-align: center;
  padding: 10px 0;
  font-size: 14px;
}