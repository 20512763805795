.BlogCategories {
  margin: 10px;
}

.BlogCategories-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.BlogCategories-item {
  margin: 0 7px 7px 0;
  display: inline-block;
}

.BlogCategories-link {
  background-color: #eee;
  border-radius: 3px;
  color: #999;
  display: block;
  font-size: 14px;
  overflow: hidden;
}

.BlogCategories-name {
  padding: 0 5px 0 10px;
}

.BlogCategories-count {
  background-color: #e25400;
  color: white;
  padding: 0 5px;
}