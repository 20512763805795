.Grid {
  margin: 0;
}

.Grid-list {
  margin: 0;
  padding: 0;
}

.Grid-item{
  display: inline-block;
  margin-bottom: 20px;
  width: 100%;
  vertical-align: top;

  &:last-child {
    margin-bottom: 0;
  }

  @include breakpoint(500px 719px) {
    width: calc((100%/2) - (20px/2));
    display: inline-block;
    margin-right: 20px;

    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  @include breakpoint(720px 768px) {
    width: calc((100%/3) - (40px/3));
    display: inline-block;
    margin-right: 20px;

    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  @include breakpoint(769px 1024px) {
    width: calc((100%/2) - (20px/2));
    display: inline-block;
    margin-right: 20px;

    &:nth-child(2n) {
      margin-right: 0;
    }
  }

  @include breakpoint(1025px) {
    width: calc((100%/3) - (40px/3));
    display: inline-block;
    margin-right: 20px;

    &:nth-child(3n) {
      margin-right: 0;
    }
  }
}


