.Footer {
  margin: 30px 0 50px;
}

.Footer-logo {
  background-image: url("../images/svg/logo.svg");
  background-position: left center;
  background-size: contain;
  background-repeat: no-repeat;
  text-indent: -9999px;
  width: 190px;
  height: 20px;
  display: block;
  position: relative;
}

.Footer-menu {
  font-size: 14px;
  list-style: outside none none;
  margin: 20px 0;
  padding: 0;
  vertical-align: top;

  @include breakpoint(500px) {
    max-width: 400px;
    columns: 2;
  }
}

.Footer-item-link {
  color: #AAA;
  line-height: 20px;
  display: inline-block;

  &:hover {
    color: white;
  }
}