.BlogPostTeaser {
  display: block;
}

.BlogPostTeaser-image {
  display: inline-block;
  max-width: 220px;
  width: 40%;
  vertical-align: middle;
}

.BlogPostTeaser-content {
  display: inline-block;
  width: 60%;
  vertical-align: middle;

  .BlogPostTeaser--hasImage & {
    padding-left: 15px;
  }
}

.BlogPostTeaser-title {
  margin: 0 0 4px;
  color: color-get(black);

  @include breakpoint(max-width 567px) {
    font-size: 18px;
  }

  .BlogPostTeaser:hover &{
    color: color-get(orange);
  }

  .BlogPostTeaser:visited & {
    color: #999;
  }
  .BlogPostTeaser:visited:hover & {
    color: color-get(orange);
  }
}

.BlogPostTeaser-lead {
  margin: 0;
  color: rgba(0,0,0,0.7);
  font-size: 14px;
  line-height: 20px;

  @include breakpoint(max-width 640px) {
    display: none;
  }
}

.BlogPostTeaser-info {
  display: block;
  margin-bottom: 4px;
  color: #999;
  font-size: 12px;
}