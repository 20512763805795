.AdHolder {
  text-align: center;
  max-width: 100%;
  margin: 15px 0;
  overflow: hidden;
}

.AdHolder--box {
  padding: 15px 0;
  background-color: white;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
}

.AdHolder-inner {
  display: inline-block;
}

// Special case
.TwoColumnStacked-sidebar .AdHolder:first-child {
  margin-top: 0;
}