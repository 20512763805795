.Vignette {
  margin: 0 0 10px;
  position: relative;

  &:before {
    content: "";
    width: 100%;
    height: 2px;
    position: absolute;
    left: 0;
    top: 20px;
    margin-top: 1px;
    background-color: #ddd;
  }

  * + & {
    margin-top: 20px;
  }
}

.Vignette-text {
  border-left: 4px solid #E25400;
  background-color: #eee;
  position: relative;
  z-index: 3;
  padding: 10px;
  text-transform: uppercase;
  line-height: 20px;
  margin: 0;
  display: inline-block;
}

.Vignette-text--white {
  background-color: white;
}

.Vignette-filters {
  float: right;
  position: relative;
  line-height: 40px;
}