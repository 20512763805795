.ArticleTeaser {
  display: block;
}

.ArticleTeaser-image {
  display: inline-block;
  max-width: 220px;
  width: 40%;
  vertical-align: middle;
}

.ArticleTeaser-content {
  display: inline-block;
  width: 60%;
  vertical-align: middle;
}

.ArticleTeaser-content--hasImage {
  padding-left: 15px;
}

.ArticleTeaser-title {
  margin: 0 0 4px;
  color: color-get(black);

  @include breakpoint(max-width 567px) {
    font-size: 18px;
  }

  .ArticleTeaser:hover &{
    color: color-get(orange);
  }

  .ArticleTeaser:visited & {
    color: #999;
  }
  .ArticleTeaser:visited:hover & {
    color: color-get(orange);
  }
}

.ArticleTeaser-lead {
  margin: 0;
  color: rgba(0,0,0,0.7);
  font-size: 14px;
  line-height: 20px;

  @include breakpoint(max-width 640px) {
    display: none;
  }
}

.ArticleTeaser-info {
  display: block;
  margin-bottom: 4px;
  color: #999;
  font-size: 12px;
}