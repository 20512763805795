.TeaserList {
}

.TeaserList-row {
  margin: 15px;

  &:not(:last-child) {
    border-bottom: 1px solid #eee;
    padding-bottom: 15px;
  }
}

.TeaserList-groupTitle {
  margin: 15px 0;
  text-align: center;
  letter-spacing: .1em;
  text-transform: uppercase;
  font-size: 26px;
}