.ContentSectionVideoSmallTeaser {
  position: relative;
  display: inline-block;
  width: 80px;
  height: 80px;
  background-color: transparent;
  background-position: center;
  background-size: cover;
}


.ContentSectionVideoSmallTeaser-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}