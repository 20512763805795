.UserPresentation {
  margin: 15px;
}

.UserPresentation-image {
  display: block;
}

.UserPresentation-heading {
  margin: 15px 0 5px;
}

.UserPresentation-description {
  font-size: 14px;
}

