$site-colors: (
  blue: #4083a9,
  blue__light: #205f82, // Not a standalone color but a variation.
  grey: #ddd,
  lightGrey: #eee,
  orange: #e25400,
  black_dark: #292929,
  black: #333,
  black__light: #999,
  lightBlack: #353535,
  green: #3c763d,
  green__light: #d6e9c6,
  green__ligther: #dff0d8,
  gold: #8a6d3b,
  gold__light: #faebcc,
  gold__ligther: #fcf8e3,
  red: #a94442,
  red__light: #ebccd1,
  red__ligther: #f2dede,
);

////
// @function color-get($key, $map: $site-colors)
// $key: key to be retrieved from the map
// $map: the map that our $key will be pulled from. Defaults to $site-colors, rarely changed
////
@function color-get($key, $map: $site-colors) {
  $value: map-get($map, $key);
  @return $value
}
