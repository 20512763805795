.ContentSectionQuoteFull {
  clear: both;
  border-left: 3px solid color-get(orange);
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.ContentSectionQuoteFull-quote {
  margin: 0;
  font-style: italic;
  font-size: 28px;
  font-family: georgia,serif;
  line-height: 1.3em;
}

.ContentSectionQuoteFull-author {
  margin-top: 8px;
  color: color-get(black__light);
  font-size: 14px;

  &:before {
    content: "– ";
  }
}
