.InterestingRaces {
  margin: 0;
  padding: 0;
  list-style: none;
}

.InterestingRaces-item:not(:last-child) {
  position: relative;
  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 1px;
    background-color: rgba(white, .3);
  }
}

.InterestingRaces-race {
  position: relative;
  display: block;
  margin: 0;
  padding: 20px 15px;
  background: #353535 0 0;
  background-size: cover;
  box-shadow: rgba(255,255,255,0.25) 0 1px 0 inset,rgba(0,0,0,0.4) 0 -1px 0 inset;
  color: #fff;
  transition: background .3s;

  &:hover, &:focus {
    color: #e25400;
    background-position: 10px 0;

    &:before {
      background-color: rgba(0,0,0,0.3);
    }
  }

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.65);
    content: "";
    transition: background-color .3s;
  }
  &:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgba(0,0,0,0.9) 0%,rgba(0,0,0,0) 50%);
    content: "";
  }
}

.InterestingRaces-race-heading {
  position: relative;
  z-index: 1;
  margin: 0;
  font-size: 16px;
  line-height: 1em;
}

.InterestingRaces-race-date {
  position: relative;
  z-index: 1;
  font-size: 13px;
  opacity: 0.75;

  .InterestingRaces-race:hover &{
    color: white;
  }
}
