.UserProfile {
  display: block;
}

.UserProfile-image {
  display: inline-block;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 10px;
}

.UserProfile-name {
  vertical-align: middle;
  margin: 15px 0 5px;
}