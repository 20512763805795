.ThemeTeaser {
  background-color: #FFF;
  display: block;
  overflow: hidden;
  color: #333;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
}

.ThemeTeaser-head {
  position: relative;
  height: 95px;
  overflow: hidden;
  background-size: cover;
  background-position: top center;

  &:before {
    position: absolute;
    content: " ";
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: transparent linear-gradient(to bottom, transparent 0%, transparent 37%, rgba(0, 0, 0, 0.9) 100%) repeat scroll 0% 0%;
    z-index: 1;
  }
}

.ThemeTeaser-info {
  color: white;
  height: 20px;
  position: absolute;
  bottom: 5px;
  padding-left: 15px;
  font-size: 14px;
  z-index: 1;
}

.ThemeTeaser-heading {
  margin: 9px 15px 5px;
}

.ThemeTeaser-lead {
  margin: 0 15px 12px;
  line-height: 1.3em;
  font-size: 14px;
  color: #999;
}