.ArticleSmallTeaser {
  display: block;
  font-size: 14px;

  &:hover, &:active {
    background-color: #D5DDE3;
  }
  &:focus {
    outline: 1px solid;
  }
}

.ArticleSmallTeaser--hasImage {
  min-height: 80px;
}

.ArticleSmallTeaser-image {
  display: inline-block;
  overflow: hidden;
  margin-right: 10px;
  width: 80px;
  height: 80px;
  border-radius: 3px;
  vertical-align: middle;
}

.ArticleSmallTeaser-content {
  display: inline-block;
  vertical-align: middle;
  line-height: 20px;
}

.ArticleSmallTeaser-content--hasImage {
  width: calc(100% - 90px);
}

.ArticleSmallTeaser-title {
  color: color-get(black);
}

.ArticleSmallTeaser-date {
  display: block;
  color: #999;
  font-size: 12px;
}
