.SidebarList {
  margin: 0;
}

.SidebarList-list {
  margin: 0;
  padding: 0;
}

.SidebarList-item{
  margin: 10px;

  &:not(:last-child) {
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
  }
}


