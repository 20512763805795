.ReviewCategoryTeaser {
  background-color: #FFF;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  display: block;
  overflow: hidden;
  color: #333;
}

.ReviewCategoryTeaser-head {
  position: relative;
  margin: 10px 15px 0;
}

.ReviewCategoryTeaser-heading {
  position: relative;
  margin: 0;

  a {
    color: #333;
  }
}

.ReviewCategoryTeaser-scoreLabel {
  position: absolute;
  top: 50%;
  right: 0;
  font-size: 11px;
  color: #999;
  line-height: 12px;
  margin-top: -6px;
}

.ReviewCategoryTeaser-list {
  margin: 10px 15px;
  padding: 0;
  list-style: none;
}

.ReviewCategoryTeaser-item {
  font-size: 14px;
  border-bottom: 1px solid #EEE;

  &:first-child {
    border-top: 1px solid #EEE;
  }

  &:hover {
    background-color: rgba(black, 0.05);
  }
}
.ReviewCategoryTeaser-link {
  position: relative;
  display: block;
  padding: 5px 30px 5px 0;
}

.ReviewCategoryTeaser-score {
  position: absolute;
  right: 0px;
  font-size: 11px;
  color: #E25400;
  top: 50%;
  line-height: 16px;
  margin-top: -8px;
}

.ReviewCategoryTeaser-seeAll {
  margin: 0 15px 10px;
  display: block;
  font-size: 14px;
  text-align: center;
}