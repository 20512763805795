.ArticleFull-filed {
  margin: 15px auto;
  max-width: 730px;
  padding-left: 15px;
  padding-right: 15px;
}

.ArticleFull-lead {
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
  max-width: 730px;
  padding-left: 15px;
  padding-right: 15px;

  @include breakpoint(600px) {
    font-weight: normal;
    font-size: 18px;
  }
}