.ToTrainingProgram {
  color: #FFF;
  font-size: 1rem;
  display: inline-block;
  width: 160px;
  text-align: right;
  vertical-align: middle;
  height: 50px;
  line-height: 50px;

  @include breakpoint(400px) {
    width: 160px;
    margin-right: 0;
    font-size: 13px;
    text-transform: uppercase;
  }

  @include breakpoint(max-width 1000px) {
    position: absolute;
    top: 0px;
    right: 9px;
    height: 50px;
    line-height: 50px;
  }

  &:hover {
    color: white;
    text-decoration: underline;
  }
}

.ToTrainingProgram-icon {
  display: none;
  margin-right: 7px;

  @include breakpoint(360px) {
    display: inline-block;
  }
}
