.RaceTeaser {
  display: block;
  color: #333;
}

.RaceTeaser-date {
  width: 70px;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  font-family: "metroflexnarrow";
  font-weight: 900;
}

.RaceTeaser-day,
.RaceTeaser-month {
  line-height: 1em;
  display: block;
}

.RaceTeaser-day {
  font-size: 40px;
  line-height: 30px;
}

.RaceTeaser-month {
  font-size: 18px;
  line-height: 18px;
  text-transform: uppercase;
}

.RaceTeaser-content {
  width: calc(100% - 70px);
  display: inline-block;
  vertical-align: middle;
  border-left: 2px solid;
}

.RaceTeaser-heading {
  margin: 0;
  padding: 5px 10px;
  line-height: 25px;
}

.RaceTeaser-heading--promoted {
  padding-bottom: 0;
}

.RaceTeaser-promoted {
  font-size: 14px;
  font-weight: normal;
  display: block;
  color: #E25400;
  white-space: nowrap;
  padding-left: 10px;
  padding-bottom: 5px;
}

.RaceTeaser-typeBar {
  height: 2px;
  background-color: #000;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
}

.RaceTeaser-typeBar-section {
  display: inline-block;
  height: 2px;
  vertical-align: top;
}

.RaceTeaser-info {
  background-color: #F1F2F2;
  color: #999;
  font-size: 14px;
  line-height: 15px;
  padding: 10px;
}

.RaceTeaser-where {
}

.RaceTeaser-type {
  white-space: nowrap;
}