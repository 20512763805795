/* apply a natural box layout model to all elements, but allowing components to change */
html {
  box-sizing: border-box;
}
*, *:before, *:after, input[type="search"] {
  box-sizing: inherit;
}

body {
  color: color-get(black);
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  background-color: color-get(lightBlack);
}

// This is for IE 9,10,11 See: http://weblog.west-wind.com/posts/2015/Jan/12/main-HTML5-Tag-not-working-in-Internet-Explorer-91011
main {
  display: block;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: "metroflexnarrow", "Open Sans", sans-serif;
}

h1, .h1 {
  font-size: 32px;
  line-height: 1em;

  @include breakpoint(break-get(twoColumn)) {
    font-size: 48px;
  }
}

h2, .h2 {
  font-size: 24px;
  line-height: 1em;
}

h3, .h3 {
  font-size: 18px;
  line-height: 1em;
}

h4, .h4 {
}

p {
  font-size: 16px;
  line-height: 1.5;
}

a {
  color: color-get(blue);
  text-decoration: none;

  &:link,
  &:visited {}

  &:hover,
  &:focus   {
    color: color-get(blue__light);
  }

  &:active  {
  }

  &.active, &.active-trail {
  }
}

img {
  max-width: 100%;
  vertical-align: top;
}
