.SlideShow {
  margin-left: auto;
  margin-right: auto;
  padding: 0 15px;
  max-width: 730px;
}

.SlideShow-wrapper,
.SlideShow-slide {
  height: auto;
}

.SlideShow-slide {
  text-align: center;
}

.SlideShow-thumb{
  display: inline-block;
  margin-top: 2.5%;
  margin-right: 2.5%;
  width: 18%;
  vertical-align: middle;
  opacity: 0.4;
  cursor: pointer;
  transition: opacity 0.5s;
  align-items: center;

  &:nth-child(5n) {
    margin-right: 0;
  }

  &.is-active{
    opacity: 1;
  }
}

.SlideShow-container{
  transition: all 0.3s;
}

