.Filters-submit {
  .js & {
    display: none;
  }
}

.Filters {
  font-size: 16px;
}

.Filters-select {
  max-width: 150px;
  opacity: 1;
  background-color: #FFF;
  border-radius: 3px;
  border: 2px solid rgb(221, 221, 221);
  padding-left: 3px;
  height: 30px;
}
