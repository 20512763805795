.ReviewTeaser {
  position: relative;
  display: block;
  @include breakpoint(500px) {
    padding-right: 110px;
  }
}

.ReviewTeaser-image {
  display: inline-block;
  width: 100px;
  vertical-align: middle;
  position: relative;
  margin-right: 15px;
  text-align: center;

  > img {
    width: auto;
  }
}

.ReviewTeaser-content {
  display: inline-block;
  width: calc(100% - 115px);
  vertical-align: middle;
}

.ReviewTeaser-label {
  line-height: 1em;
  display: block;
}

.ReviewTeaser-scoreBar {
  background-color: #ddd;
  position: relative;
  width: 100px;
  height: 10px;
  margin-top: 5px;

  @include breakpoint(500px) {
    right: 0;
    top:50%;
    margin-top: -5px;
    position: absolute;
  }

  &:after {
    content: "";
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAABAQMAAADgqcxbAAAABlBMVEUAAAD///+l2Z/dAAAAAXRSTlMAQObYZgAAAAxJREFUCNdjYGDgAQAAEAANdudZ2gAAAABJRU5ErkJggg==');
    background-size: 10px 100%;
  }
}

.ReviewTeaser-score {
  width: 0;
  background-color: #E25400;
  height: 10px;
}