.MainNavigation {
  width: calc(100% + 18px);
  margin-left: -9px;
  font-size: 1rem;

  @include breakpoint(1000px) {
    display: inline-block;
    vertical-align: middle;
    margin-left: 0;
    width: calc(100% - 190px - 160px);
  }
}

.MainNavigation-menu {
  position: relative;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  margin-top: -1px;
  max-width: 100vw;
  overflow: hidden;

  @include breakpoint(max-width break-get(menu)) {
    &.is-hiddenOnMobile {
      display: none;
    }
  }

  &.has-scroll {
    background-color: color-get(black_dark);
    overflow: hidden;
    height: 50px;
  }
}

.has-scroll {
  &:before,
  &:after {
    position: absolute;
    top: 0;
    z-index: 2;
    width: 50px;
    height: (50px - 3px);
    content: "";
    pointer-events: none;
  }

  &:before {
    left: 0;
    background: linear-gradient(to right, rgba(color-get(black_dark), 1) 30%, rgba(color-get(black_dark), 0)) repeat scroll 0 0 rgba(color-get(black_dark), 0);
  }

  &:after {
    right: 0;
    background: linear-gradient(to left, rgba(color-get(black_dark), 1) 30%, rgba(color-get(black_dark), 0)) repeat scroll 0 0 rgba(color-get(black_dark), 0);
  }
}

.MainNavigation-arrow {
  position: absolute;
  top: 0;
  z-index: 1000;
  display: none;
  width: 40px;
  height: 100%;
  background-position: left center;
  opacity: 0;
  cursor: pointer;
  transition: opacity 0.5s ease 0s;

  &.is-visible {
    opacity: 0.5;
  }

  .has-scroll & {
    display: block;
  }
}

.MainNavigation-arrow--left {
  left: 0;
}

.MainNavigation-arrow--right {
  right: 0;
}


.MainNavigation-arrow-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.MainNavigation-arrow-icon--right {
  right: 6px;
}

.MainNavigation-arrow-icon--left {
  left: 6px;
}

.MainNavigation-innerWrapper {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
  list-style: none;
  text-align: center;
  white-space: nowrap;

  .has-scroll & {
    overflow-x: auto;
    height: 65px;
    -webkit-overflow-scrolling: touch;
  }
}

.MainNavigation-item {
  position: relative;
  display: inline-block;
  text-align: center;
}

.MainNavigation-item-link {
  position: relative;
  z-index: 1;
  display: block;
  padding: 0 8px;
  height: 50px;
  white-space: nowrap;
  line-height: 50px;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  color: #fff;

  .has-scroll & {
    color: rgba(#fff, 0.7);
    font-weight: 100;

    &.is-active {
      color: #fff;
    }
  }

  &.is-first {
    padding-left: 16px;

    .has-scroll & {
      padding-left: 50px;
    }
  }

  &.is-last{
    padding-right: 16px;

    .has-scroll & {
      padding-right: 50px;
    }
  }

  &.is-active {
    @include breakpoint(max-width break-get(menu)) {
      font-weight: 600;
    }
  }

  &:hover, &:focus {
    color: color-get(orange);
    transition: color .3s;
  }

  @include breakpoint(break-get(menu)) {
    padding: 0 15px;
    color: #fff;
    font-weight: 400;
    font-size: 16px;

    &.is-first {
      padding-left: 30px;
    }

    &.is-last {
      padding-right: 30px;
    }
  }
}

.MainNavigation-item-link-activeIndecator {
  display: block;
  height: 50px;
  border-bottom: 3px solid color-get(orange);

  .has-scroll & {
    border-bottom: 4px solid color-get(orange);
  }
}

.MainNavigation-dropDown {
  position: absolute;
  top: 100%;
}