.RaceNav {
  margin: 15px auto;
  font-size: 14px;
  text-align: center;
  position: relative;
  max-width: 320px;
  height: 26px;
}

.RaceNav-current {
  margin: 0;
  display: inline-block;
  font-weight: bold;
  text-align: center;
  letter-spacing: .1em;
  text-transform: uppercase;
  font-size: 26px;
}

.RaceNav-link {
  position: absolute;
  padding: 0 15px;
  line-height: 26px;
}

.RaceNav-link--prev {
  left: 0;
}

.RaceNav-link--next {
  position: absolute;
  right: 0;
}
