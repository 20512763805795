.BodyText {
  > p,
  > ul,
  > ol
  {
    font-family: georgia,serif;
    line-height: 1.5em;
  }

  > ul,
  > ol {
    padding-left: calc(2em + 15px);
  }

  > *
  {
    margin-right: auto;
    margin-left: auto;
    max-width: 610px;
    padding-left: 15px;
    padding-right: 15px;
  }

  figcaption {
    font-size: 11px;
    margin-bottom: 5px;
    margin-top: 5px;
  }
}