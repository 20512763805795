.Tabs {
  margin: 0;
  padding: 5px 0 0 0;
  border-bottom: 1px solid #ddd;
  background-color: rgba(black, .03);
  text-align: left;
}

.Tabs-inner {
  @include container(1280px);
  padding: 0 9px;
  margin-top: 0;
  margin-bottom: 0;
}

.Tabs-tab {
  display: inline-block;
  margin-bottom: -1px;
  list-style: none;
}

.Tabs-tab-link {
  display: block;
  margin-right: 2px;
  padding: 10px 15px;
  border: 1px solid transparent;

  &.is-active, &.is-active:hover {
    border: 1px solid color-get(grey);
    border-bottom-color: transparent;
    background-color: #eee;
    color: color-get(black);
    cursor: default;
  }

  &:hover {
    border-color: color-get(lightGrey) color-get(lightGrey) color-get(grey);
    background-color: #eee;
  }
}

// Secondary

.Tabs--secondary {
  background-color: #EEE;
  border: none;
  padding: 5px 0;
}

.Tabs-tab-pill {
  border-radius: 13px;
  height: 26px;
  display: inline-block;
  padding: 0px 10px;
  font-size: 14px;
  line-height: 23px;
  margin-right: 4px;
  border: 1px solid rgb(221, 221, 221);

  &.is-active {
    background-color: rgb(64, 131, 169);
    color: white;
    border-color: #4083A9;
  }
}