.SiteTemplate {
  background-color: #eee;
}

.SiteTemplate-inner {
  @include container(1280px);
  padding: 0 9px;
}

.SiteTemplate-main {
}

.SiteTemplate-header,
.SiteTemplate-footer {
  background-color: color-get(lightBlack);
  color: #fff;
}

.SiteTemplate-header {
  box-shadow: 0px 4px 2px -2px rgba(0, 0, 0, 0.3);
  font-size: 0;
  position: relative;
}
