.Blogs {

}

.Blogs-blog {
  background-color: white;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  color: #333;
  padding: 15px;

  &:not(:last-child) {
    margin-bottom: 15px;
  }
}

.Blogs-blogAuthor {
  margin-bottom: 15px;
}