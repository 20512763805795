.Video {
  position: relative;
  overflow: hidden;
  padding-bottom: 56.25%;
  max-width: 100%;
  height: 0;
}

.Video-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
