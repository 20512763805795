.RaceTypes {
  font-size: 14px;
  margin: 10px 0 10px;
  border-bottom: 1px solid #eee;
  padding: 0 15px 10px;
}

.RaceTypes-type {
  display: inline-block;
  border: 1px solid transparent;
  padding: 0 3px;
  margin-right: 5px;

  &.is-active {
    border-radius: 3px;
    border: 1px solid #ddd;
    background-color: #eee;
  }
}

.RaceTypes-label {
  margin-right: 7px;
}

.RaceTypes-dot {
  margin-right: 3px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
}

.RaceTypes-name {
  color: #333;
}

.RaceTypes-count {
  color: #999;
  margin-left: 3px;
}
