.Pager {
  margin: 20px 0;
  padding: 0px;
  list-style: outside none none;
  text-align: center;
}

.Pager-item {
  display: inline-block;

  > a {
    padding: 3px 4px;
  }

  @include breakpoint(max-width 600px) {
    &:not(.Pager-item--next) {
      display: none;
    }
  }

}