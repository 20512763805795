/**
 * Contain floats
 *
 * Make an element expand to contain floated children.
 * Uses pseudo-elements (micro clearfix).
 *
 * 1. The space content is one way to avoid an Opera bug when the
 *    `contenteditable` attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of the
 *    element.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */
.u-cf:before,
.u-cf:after {
  content: " "; /* 1 */
  display: table; /* 2 */
}

.u-cf:after {
  clear: both;
}

/**
 * Floats
 */
.u-floatLeft {
  float: left !important;
}

.u-floatRight {
  float: right !important;
}

/**
 * Display-type utilities
 */
.u-block {
  display: block !important;
}

.u-hidden {
  display: none !important;
}

/**
 * Completely remove from the flow but leave available to screen readers.
 */
.u-hiddenVisually, .element-invisible {
  position: absolute !important;
  overflow: hidden !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
}
