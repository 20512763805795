.StatusMessages {
  margin: 20px 0;
  border: 1px solid transparent;
  border-radius: 4px;
}

.StatusMessages--status {
  border-color: color-get(green__light);
  background-color: color-get(green__ligther);
  color: color-get(green);
}

.StatusMessages--warning {
  border-color: color-get(gold__light);
  background-color: color-get(gold__ligther);
  color: color-get(gold);
}


.StatusMessages--error {
  border-color: color-get(red__light);
  background-color: color-get(red__ligther);
  color: color-get(red);
}

.StatusMessages-inner {
  margin: 15px;
}
