.Logo {
  margin: 0px auto;
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-right: 40px;

  &:after, &:before {
    transform: skew(-25deg, 0deg);
    content: "";
    display: block;
    height: 50px;
    position: absolute;
    top: 0px;
    z-index: 0;
  }
  &:after {
    width: 170px;
    background-color: #E25400;
    left: -20px;
  }

  &:before {
    width: 187px;
    background-color: #823600;
    left: -30px;
  }


  @include breakpoint(400px) {
    &:after {
      width: 190px;
    }

    &:before {
      width: 210px;
    }
  }
}

.Logo-image {
  background-image: url("../images/svg/logo.svg");
  background-position: left center;
  background-size: contain;
  background-repeat: no-repeat;
  text-indent: -9999px;
  width: 140px;
  height: 50px;
  display: block;
  position: relative;
  z-index: 1;

  @include breakpoint(400px) {
    width: 150px;
  }
}