.BlogSponsors {

}

.BlogSponsors-heading {
  color: #999;
  text-align: center;
}

.BlogSponsors-list {
  margin: 0;
  padding: 0;
}

.BlogSponsors-sponsor {
  width: calc(50% - 8px - 15px);
  display: inline-block;
  margin: 0 8px 15px 15px;
  vertical-align: middle;

  &:nth-child(2n) {
    margin: 0 15px 15px 8px;
  }
}